export default {
  /* 'VideoWidget': () => import('./Video.vue'),
  'ZoomImage': () => import('./ZoomImage.vue'),
  'GalleryMedium': () => import('./GalleryMedium.vue'),
  'GallerySmall': () => import('./GallerySmall.vue'),
  'GalleryBuilder': () => import('./GalleryBuilder.vue'), */
  'ModalDefault': () => import('./modals/ModalDefault'),
  'ModalSuccess': () => import('./modals/ModalSuccess'),
  'ModalSocial': () => import('./modals/ModalSocial'),
  'ButtonModal': () => import('./buttons/ButtonModal'),
  /*'ContFixed': () => import('./ContFixed'),
  'VistavkaBunner': () => import('./vistavka/VistavkaBunner'),

  'Offer': () => import('./Form/Offer'),
  'ManagerItem': () => import('./Form/ManagerItem'),
  'FormPhone': () => import('./Form/FormPhone'),
  'FormDate': () => import('./Form/FormDate'),

  'Subscribe': () => import('./modals/Subscribe'),
  'Badge': () => import('./badges/Badge'), */
}
